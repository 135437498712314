import React from 'react'
import {
  quotes,
  Quote,
  QuotationMark,
} from '../components/testimonialsCarousel'
import { StaticImage } from 'gatsby-plugin-image'
import classnames from 'classnames'

// This is a non-carousel list of all the quotes
const TestimonialsList = () => {
  return (
    <section>
      <div className="container mt-typist56 mt-md-typist64 mt-xl-typist96">
        <h1 className="fs-2 text-center mb-typist32 mb-md-typist64 fw-bold">
          Testimonials
        </h1>
        <div className="container d-flex justify-content-end">
          <StaticImage
            src="../images/shrub_big@2x.png"
            width={203}
            alt=""
            placeholder="none"
          ></StaticImage>
        </div>
        {quotes.map((quote, idx, arr) => (
          <div
            key={`quote-${idx}`}
            className={classnames(
              'd-flex border-dark',
              // Same spacing as the carousel testimonials
              'py-typist40',
              'py-md-typist128',
              'border-top'
            )}
          >
            <QuotationMark></QuotationMark>
            <Quote
              title={quote.title}
              id={idx + 1}
              showReadMoreLink={false}
              subtitle={quote.subtitle}
              quoteContent={quote.quoteContent}
            ></Quote>
          </div>
        ))}

        <div className="container d-flex justify-content-start border-bottom border-dark">
          <StaticImage
            src="../images/shrub_small@2x.png"
            width={104}
            alt=""
            placeholder="none"
          ></StaticImage>
        </div>
      </div>
    </section>
  )
}
export default TestimonialsList
